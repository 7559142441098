import toast from 'react-hot-toast'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'

export const useSaveEmail = () => {
  const { fetcher } = useApiWrapperWithErrorValidation({
    method: RequestMethodsEnum.post,
    badRequestHandler: error => {
      if (error.errors.fields && error.errors.fields.email)
        toast.error(error.errors.fields.email.join('\n'))
    },
  })
  const saveEmail = (email: string) => fetcher(`/api/dashboard/profile/change-email`, { email })
  return { saveEmail }
}
