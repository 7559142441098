import React, { FC, ReactElement, useState } from 'react'
import toast from 'react-hot-toast'
import { ImageCropperAndUploader } from 'shared/components/image-uploader/components/image-cropper-and-uploader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import PenIcon from 'shared/icons/pen-icon'
import { DataFile } from 'shared/types/datafile-type'
import { fileInputAcceptTypes, isAcceptTypeValid } from 'shared/utils/file-input-accept-types'

interface AvatarUploaderProps {
  onSetPhoto: (file: DataFile) => void
  children: ReactElement
  source?: string
  width?: number
  height?: number
  type?: string
}

const AvatarUploader: FC<AvatarUploaderProps> = ({
  onSetPhoto,
  children,
  width,
  height,
  source,
  type,
}: AvatarUploaderProps) => {
  const { t } = useLocoTranslation()
  const [imageFile, setImageFile] = useState<File>()
  const [isOpened, setIsOpened] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const onAddFile = (file: File) => {
    setImageFile(file)
    setIsOpened(true)
  }

  const onClose = () => {
    setImageFile(undefined)
  }
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (!isAcceptTypeValid(file.type, fileInputAcceptTypes.image)) {
        toast.error(t('dashboard.file_upload.file_not_supported'))
      } else {
        onAddFile(file)
      }
    }
    // NOTE: Reset value to have the ability to open the same file https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
    e.target.value = ''
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files && files.length) {
      const file = files[0]
      onAddFile(file)
    }
    setIsDragging(false)
  }
  return (
    <>
      <label>
        <input type="file" className="invisible h-1 absolute w-0" onChange={handleSelectFile} />
        <div
          className={`rounded-full relative cursor-pointer ${isDragging && 'ring-2 ring-blue'}`}
          style={{
            width: width,
            height: height,
          }}
          onDragStart={handleDrag}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDrag}
        >
          {children}
          <div
            className={
              'absolute bottom-0 right-0 translate-x-1/3 rounded-full bg-blue w-5 h-5 p-1 flex items-center justify-center'
            }
          >
            <PenIcon className={'fill-white w-4 h-4'} />
          </div>
        </div>
      </label>
      <ImageCropperAndUploader
        type={type}
        imageFile={imageFile}
        source={source}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        onClose={onClose}
        onUploadSuccess={onSetPhoto}
        isRounded={true}
      />
    </>
  )
}

export default AvatarUploader
