import { Float } from '@headlessui-float/react'
import { Listbox } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import FormInput from 'shared/components/form-input/index'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowDown from 'shared/icons/arrow-down-icon'
import {
  CountrySelector,
  CountrySelectorDropdown,
  usePhoneInput,
} from 'shared/react-international-phone'
import { HTMLString } from 'shared/types/html-string'

export type FormInputPhone = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  FieldLabelProps & {
    onChange: (phone: string) => void
    error?: HTMLString | null
    wrapperClassName?: string
    value: string | undefined
  }

function FormInputPhone({
  label,
  labelClassName,
  required,
  value,
  onChange,
  error,
  wrapperClassName,
  ...rest
}: FormInputPhone) {
  const { t } = useLocoTranslation()

  const dropdownRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { country, setCountry, phone, handlePhoneValueChange, onFocus, inputRef } = usePhoneInput({
    value,
    onChange: ({ unformatedPhone }) => onChange(unformatedPhone),
  })

  return (
    <div className={wrapperClassName}>
      <Listbox>
        {({ open }) => {
          return (
            <Float
              flip
              as={Fragment}
              offset={4}
              portal
              onUpdate={() => {
                if (dropdownRef.current && targetRef.current) {
                  const buttonWidth = targetRef.current.getBoundingClientRect().width
                  dropdownRef.current.style.width = `${buttonWidth}px`
                }
              }}
            >
              <div className="flex flex-col gap-1">
                <FieldLabel
                  wrapperAs={Listbox.Label}
                  label={label}
                  required={required}
                  labelClassName={`text-sm font-medium ${labelClassName}`}
                />
                <div
                  ref={targetRef}
                  className="relative w-full cursor-default overflow-hidden rounded-lg text-left"
                >
                  <Listbox.Button
                    ref={buttonRef}
                    as={'div'}
                    className="cursor-pointer absolute top-2 left-0 flex gap-1 items-center z-1 pl-3"
                  >
                    <CountrySelector
                      className={'flex'}
                      buttonStyle={{ border: 'none' }}
                      buttonClassName={'cursor-pointer'}
                      hideDropdown
                      disabled={rest.disabled}
                      selectedCountry={country}
                    />
                    <ArrowDown
                      className={`transition-transform duration-300 ${open && 'rotate-180'} `}
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                  <FormInput
                    ref={inputRef}
                    type="tel"
                    placeholder={t('dashboard.user.phone_number')}
                    inputClassName={`${open && 'border-blue'} pl-16`}
                    value={phone}
                    onChange={handlePhoneValueChange}
                    onFocus={onFocus}
                    error={error}
                    {...rest}
                  />
                </div>
              </div>
              <Listbox.Options className={'relative focus-visible:outline-none'}>
                <div className={'bg-white rounded-lg w-full'} ref={dropdownRef}>
                  <div>
                    <CountrySelectorDropdown
                      className={'rounded-md bg-white text-sm shadow-around-sm'}
                      listClassName={
                        'max-h-60 w-full overflow-auto focus:outline-none customScroll rounded-md'
                      }
                      searchInputClassName={`w-full px-4 py-2.5 rounded-t-md border border-t-1 border-b-0 border-gray/30 
                        shadow-around-sm focus:outline-none
                      `}
                      clearSearchInputClassName={`absolute top-0 right-2 w-[21px] h-[21px] translate-y-1/2 z-1 fill-darkblue cursor-pointer scale-75`}
                      listItemClassName={`text-darkblue px-4 py-2.5 border border-t-0 last:rounded-b-md border-gray/30 
                        group hover:bg-blue hover:text-white flex justify-start gap-2 items-center 
                        data-[focused=true]:bg-blue aria-[selected=true]:font-bold`}
                      listItemCountryNameClassName={'truncate'}
                      searchInputPlaceholder={t('global.search')}
                      show={open}
                      selectedCountry={country}
                      onSelect={country => {
                        setCountry(country.iso2)
                        buttonRef.current?.click()
                      }}
                    />
                  </div>
                </div>
              </Listbox.Options>
            </Float>
          )
        }}
      </Listbox>
    </div>
  )
}

export default FormInputPhone
