import { useSaveEmail } from 'modules/profile/user-settings/hooks/use-save-email'
import { UseUserSettingsInterface } from 'modules/profile/user-settings/types/user-settings-interface'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import FormInput from 'shared/components/form-input'
import { Loader } from 'shared/components/loader'
import { AdminRolesEnum } from 'shared/enums/user-roles-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import CheckIcon from 'shared/icons/check-icon'
import CloseIcon from 'shared/icons/close-icon'
import PenIcon from 'shared/icons/pen-icon'
import { isValidEmail } from 'shared/utils/email-validation'
import { KeyedMutator } from 'swr'

interface UserSettingsEmailProps {
  email?: string
  mutate: KeyedMutator<UseUserSettingsInterface>
}

const ICON_BASE_CLASS_NAME = 'w-5 h-5 main-transition-colors'

const UserSettingsEmail: FC<PropsWithChildren<UserSettingsEmailProps>> = ({
  email = '',
  mutate,
  children,
}) => {
  const { t } = useLocoTranslation()
  const { checkImpersonatorAdminRoles } = useUser()
  const canManage = checkImpersonatorAdminRoles([AdminRolesEnum.admin, AdminRolesEnum.superAdmin])

  const [isEditing, setIsEditing] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [newEmail, setNewEmail] = useState(email)

  const { saveEmail } = useSaveEmail()

  useEffect(() => {
    setNewEmail(email)
  }, [email])

  const onSave = async () => {
    try {
      if (!isValidEmail(newEmail)) {
        return toast.error(t('dashboard.validation.email_not_valid'))
      }
      setIsFetching(true)
      await saveEmail(newEmail)
      await mutate(data => {
        if (data && data.user) {
          return { ...data, user: { ...data.user, email: newEmail } }
        }
      }, false)
      toast.success(t('global.changes_saved'))
      setIsEditing(false)
    } catch {
    } finally {
      setIsFetching(false)
    }
  }

  const isSameEmail = newEmail === email

  const isEmailEmpty = newEmail === ''

  return (
    <div className="bg-blue-100 p-5">
      <div className={'flex gap-1.5 items-center text-base text-darkblue font-bold h-[42px]'}>
        <span>{`${t('global.email')}:`}</span>
        {isEditing ? (
          <FormInput
            inputClassName={'w-64'}
            value={newEmail}
            onChange={e => setNewEmail(e.target.value)}
            disabled={isFetching}
            statusIcon={isFetching && <Loader small={true} />}
          />
        ) : (
          <span>{email}</span>
        )}
        {isEditing && (
          <button
            onClick={onSave}
            disabled={isFetching || isSameEmail || isEmailEmpty}
            className={'outline-none group '}
          >
            <CheckIcon
              className={`${ICON_BASE_CLASS_NAME} stroke-gray-100 ${
                !(isFetching || isSameEmail || isEmailEmpty) &&
                'group-hover:stroke-green group-focus-visible:stroke-green'
              }`}
            />
          </button>
        )}
        {canManage && (
          <button
            disabled={isFetching}
            onClick={() => {
              if (isEditing) {
                setNewEmail(email)
              }
              setIsEditing(!isEditing)
            }}
            className={'outline-none group '}
          >
            {isEditing ? (
              <CloseIcon
                className={`${ICON_BASE_CLASS_NAME} fill-gray-100 ${
                  !isFetching && 'group-hover:fill-danger group-focus-visible:fill-danger'
                }`}
              />
            ) : (
              <PenIcon
                className={`${ICON_BASE_CLASS_NAME} group-hover:fill-blue group-focus-visible:fill-blue`}
              />
            )}
          </button>
        )}
      </div>

      {children}
    </div>
  )
}

export default UserSettingsEmail
