import { UseUserSettingsInterface } from 'modules/profile/user-settings/types/user-settings-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const USER_SETTINGS_API = '/api/dashboard/settings/profile'

export function useUserSettings() {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    UseUserSettingsInterface
  >({ method: RequestMethodsEnum.get })
  const { data: userSettings, mutate } = useSWR(() => {
    if (user) {
      return USER_SETTINGS_API
    }
    return null
  }, fetcher)
  return { userSettings, mutate }
}
